import React, { Fragment } from "react";
import { schema } from "../data/Store";
import { inspection } from "../data/Store";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import deepPurple from "@material-ui/core/colors/deepPurple";
import { ChevronRight } from "@material-ui/icons/";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { views, navigateTo } from "../navigation/Navigator";

import { useGlobal } from "reactn";

const VisitSite = () => {
  const [currentSite] = useGlobal("currentSite");
  const [mainView, setMainView] = useGlobal("mainView");
  const [, setCurrentZone] = useGlobal("currentZone");

  const theSchema = schema.get();
  const insp = inspection.get(currentSite);

  const handleOpenZone = zoneId => {
    setCurrentZone(zoneId);
    navigateTo(mainView, setMainView, views.zoneReportingPage);
  };

  const zoneStatus = (theSchema, zone, insp) =>
    theSchema.zones[zone].pointOrder.map((pointId, i) => {
      let inspP = insp.inspectionPoints[pointId] || {};
      let inspResult = inspP.score;
      let color =
        inspResult === 2
          ? "green"
          : inspResult === 1 ? "yellow" : inspResult === 0 ? "red" : "white";
      return (
        <span
          key={i}
          style={{
            width: 20,
            height: 20,
            margin: 4,
            backgroundColor: color,
            border: 1,
            borderWidth: 1,
            borderRadius: 3,
            borderStyle: "solid",
            color: "gray"
          }}
        />
      );
    });

  return (
    <Fragment>
      {/* <Slide direction="left" in={this.state.in} mountOnEnter unmountOnExit> */}
      <List>
        <Fragment>
          {theSchema.zoneOrder.map((zone, i) =>
            <Fragment key={i}>
              <ListItem divider button onClick={() => handleOpenZone(zone)}>
                <Avatar
                  style={{
                    backgroundColor: i % 2 ? deepOrange[500] : deepPurple[500]
                  }}
                >
                  {theSchema.zones[zone].name.substr(0, 1)}
                </Avatar>
                <ListItemText
                  primary={theSchema.zones[zone].name}
                  secondary={
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row"
                      }}
                    >
                      {zoneStatus(theSchema, zone, insp)}
                    </span>
                  }
                />
                <ChevronRight />
              </ListItem>

              {/* <Card style={{ margin: 10 }}>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor:
                          i % 2 ? deepOrange[500] : deepPurple[500]
                      }}
                    >
                      {theSchema.zones[zone].name.substr(0, 1)}
                    </Avatar>

                    <Typography style={{ margin: 20 }} variant="h5">
                      {theSchema.zones[zone].name}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 60
                    }}
                  >
                    
                  </div>
                </CardContent>
                <Divider />
                <CardActions>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between"
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        handleOpenPictures(currentSite, zone);
                      }}
                    >
                      <PhotoCamera />
                      Ta bilder
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => {
                        handleOpenZone(currentSite, zone);
                      }}
                    >
                      <AssignmentTurnedIn />
                      Sjekkpunkter
                    </Button>
                  </div>
                </CardActions>
              </Card> */}
            </Fragment>
          )}
        </Fragment>
      </List>
      {/* </Slide> */}
    </Fragment>
  );
};

export default VisitSite;
